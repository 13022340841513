jQuery(document).ready(function() {
    jQuery('#right-menu').sidr({
        name: 'menu-mobile',
        side: 'right',
        source: '.menu-main-container'
    });

    var data = jQuery('.brand-list');
    jQuery.each(data, function(value) {
         var n = jQuery(this.children.length);
         if (n[0] >= 7) {
            jQuery(this).addClass('column');
         }
    });


// Make ColorBox responsive
    jQuery.colorbox.settings.maxWidth  = '95%';
    jQuery.colorbox.settings.maxHeight = '95%';

    // ColorBox resize function
    var resizeTimer;
    function resizeColorBox()
    {
        if (resizeTimer) {
            clearTimeout(resizeTimer);
        }
        resizeTimer = setTimeout(function() {
                if (jQuery('#cboxOverlay').is(':visible')) {
                        jQuery.colorbox.resize(true);
                }
        }, 300);
    }

    // Resize ColorBox when resizing window or changing mobile device orientation
    jQuery(window).resize(resizeColorBox);
    window.addEventListener("orientationchange", resizeColorBox, false);

    //Photo Colorbox Init
    jQuery(".gallery > li > a").colorbox({
        initialWidth: 230,
        initialHeight: 155
    });



    //Homepage Simple Slideshow
    jQuery("#slideshow > img:gt(0)").hide();

    setInterval(function() {
      jQuery('#slideshow > img:first')
        .fadeOut(1000)
        .next()
        .fadeIn(1000)
        .end()
        .appendTo('#slideshow');
    },  3000);

    // Hack for Bootstrap Modals and Z-index
    jQuery('.modal').on('shown.bs.modal', function() {
        jQuery(this).before(jQuery('.modal-backdrop'));
        jQuery('.content-info').css("z-index", "-1");
        jQuery(this).css("z-index", parseInt(jQuery('.modal-backdrop').css('z-index')) + 1);
    });

    jQuery('.modal').on('hide.bs.modal', function() {
        jQuery('.content-info').css("z-index", "0");
    });
});

