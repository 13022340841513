jQuery(document).ready(function() {
   jQuery('#menu-main > .menu-item-has-children').hover(function() {
       jQuery(this).addClass('hover');
   }, function() {
       jQuery(this).removeClass('hover');
   });

    jQuery('#menu-main > .sub-menu').hover(function() {
        jQuery(this).addClass('hover');
        jQuery(this).parent().addClass('hover');
    }, function() {
        jQuery(this).removeClass('hover');
        jQuery(this).parent().removeClass('hover');
    });

});